<template>
  <div
    style="
      background-color: #f6f8fc;
      padding-top: 140px;
      padding-bottom: 70px;
      margin-top: 70px;
    "
    :style="
      $vuetify.breakpoint.smAndDown
        ? 'padding-top: 40px; margin-top: 40px;'
        : ''
    "
  >
    <v-main>
      <v-container>
        <v-row no-gutters align="center">
          <div
            style="
              width: 12px;
              height: 12px;
              background: #144fa9;
              border-radius: 50%;
            "
          />
          <v-col class="px-0 py-0">
            <h2
              style="
                color: var(--Black, #1b1b1b);
                font-family: 'MacPaw Fixel';
                font-size: 32px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
                margin-left: 8px;
              "
            >
              {{ "why_choose_us_section" | localize }}
            </h2>
          </v-col>

          <v-row
            no-gutters
            align="center"
            justify="end"
            v-if="!$vuetify.breakpoint.smAndDown"
          >
            <div
              class="iconBackground swiper-button-prev-choose-us"
              slot="button-prev"
              style="margin-right: 24px"
            >
              <div class="swipeBtn" style="transform: rotate(180deg)" />
            </div>
            <div
              class="iconBackground swiper-button-next-choose-us"
              slot="button-next"
            >
              <div class="swipeBtn" />
            </div>
          </v-row>
        </v-row>
      </v-container>
    </v-main>
    <swiper
      ref="mySwiper"
      :options="swiperOptions"
      style="margin-top: 40px; margin-left: 10svw"
      v-if="!$vuetify.breakpoint.smAndDown"
    >
      <swiper-slide
        v-for="i in 4"
        :key="i"
        :cssMode="true"
        :navigation="true"
        :pagination="true"
        :mousewheel="true"
        :keyboard="true"
        :modules="swiperOptions.modules"
      >
        <choose-us-card /> </swiper-slide
    ></swiper>
    <swiper
      ref="mySwiper"
      :options="swiperMobileOptions"
      style="margin-top: 40px; margin-left: 10svw"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <swiper-slide
        v-for="i in 4"
        :key="i"
        :cssMode="true"
        :navigation="true"
        :pagination="true"
        :mousewheel="true"
        :keyboard="true"
        :modules="swiperMobileOptions.modules"
      >
        <choose-us-card /> </swiper-slide
    ></swiper>
  </div>
</template>

<script>
import { Pagination, Navigation } from "vue-awesome-swiper";
import chooseUsCard from "./chooseUsCard.vue";
export default {
  components: { chooseUsCard },
  data: () => ({
    swiperOptions: {
      slidesPerView: 2.5,
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next-choose-us",
        prevEl: ".swiper-button-prev-choose-us ",
      },
      modules: [Navigation, Pagination],
    },
    swiperMobileOptions: {
      slidesPerView: 1.1,
      spaceBetween: 20,
      pagination: {
        el: ".swiper-pagination",
      },
      navigation: {
        nextEl: ".swiper-button-next-choose-us",
        prevEl: ".swiper-button-prev-choose-us ",
      },
      modules: [Navigation, Pagination],
    },
  }),
};
</script>

<style scoped>
.iconBackground {
  width: 48px;
  height: 48px;
  display: grid;
  place-items: center;
  background: #e7edf6;
  border-radius: 50%;
  z-index: 10;
  cursor: pointer;
  user-select: none;
}
.iconBackground:hover {
  background: #00318b;
}
.iconBackground:hover .swipeBtn {
  background-color: #fafafa;
}
.swipeBtn {
  width: 10px;
  height: 18px;
  background-color: #1b1b1bcc;
  -webkit-mask: url("../../../../assets/img/iconsSvg/swiperRightIcon.svg")
    no-repeat center;
  mask: url("../../../../assets/img/iconsSvg/swiperRightIcon.svg") no-repeat
    center;
}
</style>