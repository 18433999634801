<template>
  <div class="chooseUsCard">
    <v-row no-gutters align="center">
      <div class="chooseUsCardIconBackgroud">
        <img
          src="@/assets/img/services.png"
          width="64px"
          height="64px"
          style="object-fit: cover"
          alt="service icon"
        />
      </div>
      <v-col style="margin-left: 20px">
        <p class="chooseUsCardTitle">Надійний перевізник</p>
        <span class="chooseUsCardText"
          >Надійний перевізник з більш ніж десятирічним досвідом у галузі
          перевезень</span
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@media only screen and (min-width: 1024px) {
  .chooseUsCard {
    width: max-content;
    max-width: 460px;
    height: max-content;
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  }
  .chooseUsCardIconBackgroud {
    width: 104px;
    height: 104px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    border: 1px solid #e7edf6;
  }
}
@media only screen and (max-width: 1023px) {
  .chooseUsCard {
    width: max-content;
    max-width: 280px;
    height: max-content;
    padding: 16px;
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.05);
  }
  .chooseUsCardIconBackgroud {
    width: 96px;
    height: 96px;
    display: grid;
    place-items: center;
    border-radius: 50%;
    border: 1px solid #e7edf6;
  }
}
.chooseUsCardTitle {
  color: #1b1b1b;
  font-family: "MacPaw Fixel Display";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 8px;
}
.chooseUsCardText {
  color: var(--Black, #1b1b1b);
  font-family: "MacPaw Fixel Display";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>